import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import AuthEnterOTP from './AuthEnterOTP';
import AuthGetOTP from './AuthGetOTP';

function generateSecretKey() {
  return CryptoJS.lib.WordArray.random(32).toString();
}

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Mobile number is not valid')
    .required('Mobile number is required'),
});

const validationSchemaOTP = Yup.object().shape({
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Mobile number is not valid')
    .required('Mobile number is required'),
  otp: Yup.string()
    .matches(/^\d{6}$/, 'OTP must be a 6-digit number')
    .required('OTP is required'),
});

function LoginRightSide({ handleLoading, isLoading }) {
  const [secretKey] = useState(() => {
    return generateSecretKey();
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOTP, setIsOTP] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const getOTPButtonRef = useRef(null);

  const [initialValues, setInitialValues] = useState({
    mobileNumber: '',
    otp: '',
  });

  useEffect(() => {
    console.log(isOTP);
  }, [isOTP]);

  const handleInitialValues = (values) => {
    setInitialValues(values);
  };

  const handleIsOTP = (bool) => {
    console.log(bool);
    setIsOTP(bool);
  };

  const handleSendOTPAgain = () => {
    setIsOTP(false); // Set isOTP to false
    setTimeout(() => {
      if (getOTPButtonRef.current) {
        getOTPButtonRef.current.click(); // Trigger click event on Get OTP button
      }
    }, 100); // Use a timeout to ensure the ref is ready
  };

  return (
    <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        <div className="sh-11">
          <NavLink to="/">
            <div className="logo-default" />
          </NavLink>
        </div>
        <div className="mb-5">
          <h2 className="cta-1 mb-0 text-primary">Welcome,</h2>
          <h2 className="cta-1 text-primary">let's get started!</h2>
        </div>
        <div className="mb-5">
          <p className="h6">Please use your credentials to login.</p>
        </div>
        <div>
          {isOTP ? (
            <AuthEnterOTP
              initialValues={initialValues}
              validationSchemaOTP={validationSchemaOTP}
              handleLoading={handleLoading}
              dispatch={dispatch}
              secretKey={secretKey}
              navigate={navigate}
              isLoading={isLoading}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              handleIsOTP={handleIsOTP}
              handleSendOTPAgain={handleSendOTPAgain}
            />
          ) : (
            <AuthGetOTP
              initialValues={initialValues}
              handleInitialValues={handleInitialValues}
              validationSchema={validationSchema}
              handleLoading={handleLoading}
              handleIsOTP={handleIsOTP}
              isLoading={isLoading}
              mobileNumber={mobileNumber}
              setMobileNumber={setMobileNumber}
              getOTPButtonRef={getOTPButtonRef}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginRightSide;
